/*  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400'); */


/* html, body{
    height: 100%;  
  }
  
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f1f1f1;
    font-family: 'Roboto', sans-serif;
  } 
  
  .container {
      position: relative;
      width: 320px;
      height: 200px;
      background: #ececec;
      font-family: 'Roboto', sans-serif;
  }*/

.floating-menu {
	position: absolute;
	right: 16px;
	bottom: 8px;
	height: 32px;
    text-align: right;
	/*transition: height 250ms ease-in-out;*/
}

.floating-menu.open {
	height: 200px;
	/* right: 16px; */
	bottom: 0px;
   /*  text-align: right; */
}

.floating-menu.open-oneelement {
	height: 100px;
	/* right: 16px; */
	bottom: -2px;
	/*padding: 0px 0px 0px 0px;*/
   /*  text-align: right; */
}


.floating-menu.open .floating-menu-item {
	margin-top: 8px;
}

.floating-menu.open-oneelement .floating-menu-item {
	margin-top: 8px;
}


.floating-menu-item label{
	display: inline-block;
	margin-right: 8px;
	padding: 4px 10px;
    border-radius: 10px;
    background: rgba(195, 193, 193, 0.25);
   /*  background: rgba(255, 255, 255, 0.25); */
	opacity: 0;
	font-size: 14px;
    transition: opacity 250ms ease-in-out;
    color: rgb(0, 0, 0);
}

.floating-menu.open .floating-menu-item label {
    opacity: 0.6;
    font-size: 14px;
    /*font-family: 'Roboto', sans-serif;*/
    font-family: 'Lato', 
}

.floating-menu.open-oneelement .floating-menu-item label {
    opacity: 0.3;
    /* font-size: 14px; */
    /*font-family: 'Roboto', sans-serif;*/
    font-family: 'Lato', 
}

.floating-menu-icon{
	display: inline-block;
	width: 42px;
	height: 42px;
	line-height: 42px;
	vertical-align: middle;
	background: #3eb5b5 ;
	border-radius: 50%;
	border: none;
	box-shadow: 0 2px 4px rgba(0,0,0,.2);
    text-align: center;
    padding-left: 4px;
}

.floating-menu-icon-other{
	display: inline-block;
	width: 42px;
	height: 42x;
	line-height: 42px;
	vertical-align: middle;
	background: #cdd0d0 ;
	border-radius: 50%;
	border: none;
	box-shadow: 0 2px 4px rgba(0,0,0,.2);
    text-align: center;
    padding-left: 4px;
}

.floating-menu-icon .material-icons {
	font-size: 18px;
	color: #fff;
	vertical-align: middle;
}

.floating-menu-icon-other .material-icons {
	font-size: 18px;
	color: #fff;
	vertical-align: middle;
}