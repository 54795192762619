.image-div {
    position: relative;
    float:left;
    margin:0px;
}

.image-div:hover .hidden_img
  {
    display: block;
  }

.image-div .hidden_img {
    position:absolute;
    /*top:200px;
    margin-left: auto;
    margin-right: auto;
    width: 320px;*/
    left:20px;
    bottom:20px;
    display:none;
}

.image-div-ingredient {
  position: relative;
  float:left;
  margin:0px;
}

.image-div-ingredient .hidden_button {
  position:absolute;
  left:0px;
  width: 100%;
  bottom:0px;
  visibility: hidden;
}

.image-div-ingredient:hover .hidden_button{
    display: block;
    position:absolute;
    left:0px;
    width: 100%;
    bottom:0px;
    visibility: visible;
}