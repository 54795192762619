

.img-fluid {
    max-width: 90%;
    height: auto;
    margin-bottom: 1em;
  }

  .jumbotron {
    margin-bottom: 1em;

}

.jumbotron h1 {
    color: black;
    font-size: 24px;
    font-weight: bold
}

.jumbotron p {
    color: grey;
    font-size: 14px
}

 /* The sidebar menu */
 .sidenav {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 160px; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 1; /* Stay at the top */
    left: 0;
    background-color: #111; /* Black */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 20px;
}

/* The navigation menu links  */
.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
}

/* When you mouse over the navigation links, change their color  */
.sidenav a:hover {
    color: #f1f1f1;
}

/* Style page content  */
.main {
    margin-left: 160px; /* Same as the width of the sidebar */
    padding: 0px 10px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}

.ui.page.modals.transition.visible {
    display: flex !important;
}

.ui.modal {
    margin-top: 0 !important;
}

.visible.transition {
    display: unset !important;
  }
  
.dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
    display: -ms-flexbox;
    display: flex !important;
    opacity: 1;
}

.modal { position: relative;} 